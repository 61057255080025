import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { Container, Table } from "react-bootstrap"


// import BackgroundImage from 'gatsby-background-image'

const pageInfo = () => (
  <Layout>
    <SEO title="Galvanized Hardware Cloth and Others" />
    <Container className="singleCol">
      <h1>Galvanized Hardware Cloth and Others</h1>
      <p>Galvanized Hardware Cloth is used for many industrial and commercial screening applications and is one of the most economical means of resisting rust and corrosion. Applications include screens for vents, louvers, spark arrestors, finger guards, baskets, racks, trays, cages, dehydrators and miscellaneous sheet metal fabrications.</p>
      <p><strong>Plus, the largest use now – Gopher Mesh!</strong></p>
        <Table striped bordered responsive className="noWrap">
        <thead>
        <tr>
            <th>Mesh Size</th>
            <th>Gauge</th>
            <th>Width x 100 ' Rolls</th>
            <th>Weight</th>
        </tr>
        </thead>
        <tbody>
            <tr>
                <td>1/2" x 1/2"</td>
                <td>19</td>
                <td>24", 36", 48", 60", 72"</td>
                <td>25 pounds per 100 square feet</td>
            </tr>
            <tr>
                <td>1/4" x 1/4"</td>
                <td>23</td>
                <td>24", 36", 48"</td>
                <td>24.5 pounds per 100 square feet</td>
            </tr>
            <tr>
                <td>1/8" x 1/8"</td>
                <td>27</td>
                <td>36" and 48"</td>
                <td>20.5 pounds per 100 square feet</td>
            </tr>
        </tbody>
    </Table>
    </Container>
  </Layout>
)

export default pageInfo
